<template>
  <div class="SingleChangeLevelBtn">
    <ColorTextBtn @click="openDialog" class="single">修改等级</ColorTextBtn>
    <el-dialog v-el-drag-dialog :visible.sync="dialogVisible" append-to-body width="50%">
      <template slot="title">
        <span class="dialog-title">修改会员等级</span>
      </template>
      <el-row class="userInfoRow" v-loading="tableLoading">
        <el-col :span="6">
          <span class="span-title">分销商名称:</span>
          <span>{{ data.nickName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="span-title">登录账号:</span>
          <span>{{ data.accountName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="span-title">当前等级:</span>
          {{ levelName(data) }}
        </el-col>
        <el-col :span="6">
          <span class="span-title">当前采购额:</span>
          <span class="span-font">{{
            `￥${(data.paymentAccountSimple && data.paymentAccountSimple.salesAmount) || 0}`
          }}</span>
        </el-col>
      </el-row>
      <el-form :model="vipLevel" size="small" :rules="singleChangeFormRules" ref="levelForm" label-width="100px">
        <el-form-item label="修改等级至:" prop="level">
          <el-select
            v-model="vipLevel.level"
            placeholder="请选择会员等级"
            style="width: 200px"
            @change="selectLevelChange"
            clearable
          >
            <el-option v-for="item in VIPLevelList" :key="item.id" :label="item.levelName" :value="item.id"></el-option>
          </el-select>
          <p class="input-tips">产品折扣会调整至修改后会员等级</p>
        </el-form-item>
      </el-form>
      <customTable v-loading="tableLoading" :data="tableData" :option="option"></customTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogClick" :loading="loaded">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import customTable from '@/views/components/customTable'
import { tableCol as cols } from './cols.js'
import { getVipUserById, changeUserVipLevel } from '@/api/vipLevel'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'SingleChangeLevelBtn',
  components: {
    customTable
  },
  props: {
    id: {
      type: Number
    },
    VIPLevelList: {
      type: Array,
      required: true
    },
    init: {
      type: Function
    }
  },
  data() {
    return {
      dialogVisible: false,
      vipLevel: {
        level: ''
      },
      singleChangeFormRules: { level: [{ required: true, message: '请选择会员等级', trigger: 'change' }] },
      tableLoading: false,
      tableData: [],
      userVipId: '',
      loaded: false,
      data: {}
    }
  },
  computed: {
    memberLevelName() {
      return (row) => {
        const nameList = this.VIPLevelList.filter(({ id }) => {
          return id === row.currentLevelId
        })
        return nameList[0]?.levelName
      }
    },
    cols() {
      let cloneCols = cloneDeep(cols)
      let memberLevelId = cloneCols.find((item) => item.prop === 'memberLevelId') || {}
      memberLevelId.formatter = this.memberLevelName
      return cloneCols
    },
    option() {
      return {
        topPage: false,
        menu: false,
        column: this.cols
      }
    },
    levelName() {
      return (row) => {
        return row?.paymentAccountSimple?.memberLevel?.levelName || '暂无'
      }
    }
  },
  methods: {
    openDialog() {
      this.getUserVIPLevelInfo()
      this.dialogVisible = true
    },
    dialogClick() {
      this.$refs['levelForm'].validate(async (valid) => {
        if (valid) {
          const query = {
            memberLevelId: this.vipLevel.level,
            id: this.userVipId
          }
          this.loaded = true
          const res = await awaitResolve(
            changeUserVipLevel(query)
          )
          if (res) {
            this.$message.success('操作成功')
            this.init()
          } else {
            this.$message.error('操作失败')
          }
          this.loaded = false
          this.dialogVisible = false
        } else {
          return false
        }
      })
    },
    selectLevelChange(val) {},
    // 获取用户VIP相关信息
    async getUserVIPLevelInfo() {
      const newQuery = { id: this.id }

      this.tableLoading = true
      const { detail } = await getVipUserById(newQuery)

      this.data = detail

      this.vipLevel.level = detail?.paymentAccountSimple?.memberLevelId
      this.userVipId = detail?.paymentAccountSimple?.id
      this.tableData = detail?.memberLevelChangeRecordList

      this.tableLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
.SingleChangeLevelBtn {
  display: inline-block;
  ::v-deep .el-dialog__header {
    text-align: left;
    color: #1a1a1a;
    font-size: 16px;
  }
  ::v-deep .el-dialog__body {
    text-align: left;
  }
  ::v-deep .el-form-item__error {
    margin-top: 0px;
  }
}
.input-tips {
  margin: 3px 0 0 0;
  font-size: 12px;
  line-height: 12px;
  color: #595961;
}
.userInfoRow {
  margin-bottom: 20px;
  .span-title {
    color: #1a1a1a;
    font-size: 14px;
  }
  .span-font {
    color: #ffba00;
  }
}
</style>
